(function ($) {
   
    /**
     * inits
     */
    var menuItem = $('.main-menu__top-item').find('a.main-menu__link'),
        megamenu = '.js-megamenu'

    /**
     * show megamenu
     */
    $(menuItem).hover(function() {
        var currentMegamenu = $(this).parent().find(megamenu)

        close_megamenu()

        if (currentMegamenu.length > 0) {
            currentMegamenu.addClass('active')
            $(this).addClass('active')
        }
        else {
            close_megamenu()
        }
    });

    $('.js-hide-megamenu').hover(function() {
        close_megamenu()
    })

    $('#main-navbar').mouseleave(function() {
        close_megamenu()
    })

    /**
     * close megamenu
     */
    function close_megamenu() {
        $(megamenu).each(function() {
            $(this).removeClass('active')
        })
        menuItem.each(function() {
            $(this).removeClass('active')
        })
    }
 
 })(jQuery);