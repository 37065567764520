(function ($) {
    var $cityInput = $('#js-cities-search-ajax'),
        $streetInput = $('#js-street-search-ajax'),
        $checkButton = $('#js-check-localization'),
        $checkContent = $('#js-localization-popup-content')
    /**
     * wybrana lokalizacja
     */
    var cookieLocalization = JSON.parse(unescape(getCookie('localization')))

    if (cookieLocalization) {
        var selectedCity = cookieLocalization['id'],
            selectedStreet = cookieLocalization['index']
    }

    /**
     * select2 - pobranie miejscowości
     */
    $cityInput.select2({
        minimumInputLength: 2,
        language: 'pl',
        ajax: {
            url: ajax.mainUrl + '/wp-json/configurator/cities/',
            dataType: 'json',
            processResults: function (data) {
                return {
                    results: data
                }
            }
        }
    })

    /**
     * select2 - input dla listy ulic
     */
    $streetInput.select2({
        minimumInputLength: 1,
        language: 'pl'
    });

    /**
     * ładowanie listy ulic po wybraniu miasta
     */
    $cityInput.on('change', function(){
        ajaxLoadStreets($(this).val(), false)
    })

    if (selectedCity) {
        $cityInput.trigger('change', true)
        // console.log(JSON.parse(unescape(getCookie('localization'))))
    }

    /**
     * ładowanie listy ulic
     * 
     * @param {integer} post_id - id wpisu
     * @param {boolean} reload - jeśli 1 nie przeładowuje domyślnie wybranej opcji
     */
    function ajaxLoadStreets(post_id, reload = false) {
        $streetInput.parent().addClass('loading')
        if (reload) {
            $streetInput.val(null).trigger('change').prop("disabled", true).empty().trigger('change')
        }
        else {
            $streetInput.prop("disabled", true)
        }
        
        $.ajax({
            url:ajax.url,
            data: {
                action: 'load_streets',
                post_id: post_id
            },
            type:'post',
            success:function(data){
                var results = JSON.parse(data);
                console.log(results)

                $streetInput.select2({
                    minimumInputLength: 1,
                    language: 'pl',
                    data: results
                })

                $streetInput.prop("disabled", false)
                $streetInput.parent().removeClass('loading')
            }
        })
        return false;
    }

    /**
     * popup trigger
     */
    fireModal()

    $checkButton.click(function(e) {
        e.preventDefault()
        fireModal()
    })

    function fireModal() {
        $checkButton.magnificPopup({
            type: 'inline',
            fixedContentPos: false,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: true,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: 'my-mfp-zoom-in'
        })

        ajaxLoadContent($cityInput.val(), $streetInput.val())
    }

    /**
     * ajax load modal content
     */
    function ajaxLoadContent(city, street) {
        $checkContent.html('<div class="localization-modal__placeholder"><div class="loader-container"><div class="loader"></div></div></div>').addClass('loading')

        $.ajax({
            url:ajax.url,
            data: {
                action: 'load_modal_content',
                city: city,
                street: street
            },
            type:'post',
            success:function(data){
                $checkContent.html(data).removeClass('loading')

                //reinit popovers
                $('[data-toggle="popover"]').popover({
                    trigger: 'focus'
                })
            }
        })

        return false
    }

    /**
     * parsowanie zmiennych GET
     */
    function getRequests() {
        var s1 = location.search.substring(1, location.search.length).split('&'),
            r = {}, s2, i
        for (i = 0; i < s1.length; i += 1) {
            s2 = s1[i].split('=')
            r[decodeURIComponent(s2[0]).toLowerCase()] = decodeURIComponent(s2[1])
        }
        return r
    }

})(jQuery);