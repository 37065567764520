(function ($) {
    // zamykanie popupu

    $('.canal-box').click(function() {
        $(this).find('.canal-popup').addClass('active');
    })

    $('body').on('click', '.canal-popup.active', function() {
        $(this).removeClass('active');
    })
  
})(jQuery);