(function ($) {
    // Accordion
    $( function() {
        $(".accordion").accordion({
            heightStyle: "content",
            header: ".accordion-title",
            active: "false",
            collapsible: true
        });
    });
})(jQuery);